<template>
  <el-table
    class="dynamicTable"
    ref="table"
    id="el-table"
    :data="tableData"
    height="100%"
    :row-class-name="tableRowClassName"
    :cell-class-name="tableCellClassName"
    :stripe="false"
    :border="border"
    v-loading="loading"
    @cell-dblclick="rowClick"
    @selection-change="handleSelectionChange"
    @sort-change="handleSortChange"
  >
    <el-table-column
      v-if="paixu"
      :label="paixu"
      align="center"
      width="60"
      type="index"
      :index="1"
    >
    </el-table-column>
    <el-table-column
      v-if="showChecked && tableHeader.length > 0"
      type="selection"
      align="center"
      width="50"
    >
    </el-table-column>

    <template v-for="(item, index) in tableHeader">
      <table-column
        v-if="item.childList && item.childList.length"
        :key="index"
        :coloumn-header="item"
      ></table-column>

      <el-table-column
        v-else
        :width="item.width"
        :sortable="item.sortable"
        :key="index"
        :label="$t(item.title)"
        :prop="item.prop"
        align="center"
      >
        <template slot-scope="scope">
          <span v-html="$t(scope.row[item.prop])" class="myHtml"> </span>
        </template>
      </el-table-column>
    </template>
    <el-table-column
      :label="$t('label.quickbooks.searchresault.action')"
      v-if="isShowOpration && tableHeader.length > 0"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          v-for="(item, index) in oprationBtnList"
          :key="index"
          @click="handleClick(scope, item)"
        >
          <span v-if="scope.row.isTemplate && scope.row.isTemplate == '否'">
            {{ $t(item.label) }}
          </span>
          <span v-if="!scope.row.isTemplate">
            {{ $t(item.label) }}
          </span>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableColumn from "./v-tableColumn";
export default {
  props: {
    paixu: {
      type: String,
      default: null,
    },
    showChecked: {
      type: Boolean,
      default: false,
    },
    // 表格的数据
    tableData: {
      type: Array,
      required: true,
    },
    // 多级表头的数据
    tableHeader: {
      type: Array,
      required: true,
    },
    // height: {
    //   type: [String, Number, undefined],
    //   default: "",
    // },
    tableRowClassName: {
      type: Function,
      default: null,
    },
    tableCellClassName: {
      type: Function,
      default: () => [],
    },
    border: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否展示操作按钮
    isShowOpration: {
      type: Boolean,
      default: false,
    },
    // 操作按钮列表
    oprationBtnList: {
      type: Array,
      default: () => [],
    },
    // 是否可以行内点击
    isClick: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    tableColumn,
  },
  mounted() {
    this.tableGetMore();
  },
  methods: {
    /**
     * 上拉加载更多数据
     */
    tableGetMore() {
      let dom = document.querySelector(
        ".dynamicTable > .el-table__body-wrapper"
      );
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight - 10;
        if (scrollDistance <= 0) {
          this.$emit("loadScroll");
        }
      });
    },
    /**
     *设置背景色
     */
    // getCellStyle(type ) {
    //   // if(type === 'date'){
    //     let cellstyle = {
    //       'vertical-align': 'top'
    //     };
    //     return cellstyle
    //   // }

    // },
    /**
     * 修改排序
     */
    sortData(prop) {
      return function sort(a, b) {
        if ("-" == a[prop]) {
          return -1;
        }
        return parseFloat(a[prop]) - parseFloat(b[prop]);
      };
    },
    // 滚动加载

    load() {
      this.$emit("loadScroll");
    },
    // 操作按钮
    handleClick(scope, item) {
      if (item.action == "del") {
        // 删除
        this.$emit("del", scope.row);
      }
    },
    // 行内点击
    rowClick(row) {
      if (this.isClick) {
        this.$emit("handleClick", row);
      } else {
        return;
      }
    },
    /**
     * 选择项
     */
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange",val)
    },
    /**
     * 自定义排序
     */
    handleSortChange( val ){
      this.$emit("handleSortChange", val )
    }
  },
};
</script>
<style scoped>
.cell-bg {
  background-color: #000 !important;
  color: #fff;
}
.myHtml {
  white-space: pre-wrap;
}
.el-table td {
  vertical-align: top !important;
}
</style>
