class Api {
    static getInstance() {
        if (!this.instance) {
            this.instance = new Api();
        }
        return this.instance;
    }

    // 回收站列表
    list(axios, params) {
        return axios.post("/recycle/list", params)
    }
    // 取消删除
    unDelete(axios, params) {
        return axios.post("/recycle/unDelete", params)
    }
    // 清空回收站
    clear(axios, params) {
        return axios.post("/recycle/clear", params)
    }
}
export default Api