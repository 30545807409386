<template>
  <div class="recycle">
    <div class="head">
      <div class="new_title">{{ $t("label.recycle") }}</div>
      <div class="desc">
        {{ $t("label.recycle.dis.tip2") }}<br />
        {{ $t("label.recycle.dis.tip3") }}
      </div>
    </div>
    <div class="content">
      <div class="seach">
        <el-select size="small" v-model="form.delScope" @change="handleSeacher">
          <el-option value="1" :label="$t('label.recycle.my')"></el-option>
          <el-option value="2" :label="$t('label.recycle.all')"></el-option>
        </el-select>
        <el-input
          size="small"
          v-model="form.reseachName"
          @keyup.enter.native="handleSeacher"
        ></el-input>
        <el-button size="small" @click="handleSeacher">{{
          $t("label.searchs")
        }}</el-button>
        <el-button size="small" @click="handleCancelDelete">{{
          $t("label.delete.cancel")
        }}</el-button>
        <el-button size="small" @click="handleClearRecycle('mine')">{{
          $t("label.recycle.clearmy")
        }}</el-button>
        <!-- 普通用户不显示清空贵组织的回收站按钮 -->
        <el-button v-show="profileId === 'aaa000001'" size="small" @click="handleClearRecycle('org')">{{
          $t("label.recycle.clearall")
        }}</el-button>
      </div>
      <div class="cusTable">
        <dynamic-table
          class="myTable"
          ref="dynamicTable"
          :tableHeader="tableData.title"
          :table-data="tableData.data"
          :loading="loading"
          :showChecked="true"
          @loadScroll="loadScroll"
          @handleSelectionChange="handleSelectionChange"
          @handleSortChange="handleSortChange"
        ></dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 回收站
 */
import api from "./api.js";
import dayjs from "dayjs";
import dynamicTable from "@/views/systemSettings/components/customUI/components/Table/v-dynamicTable.vue";
export default {
  components: {
    dynamicTable,
  },
  data() {
    return {
      // 是否是管理员
      profileId: JSON.parse(localStorage.getItem("userInformation"))?.data.profileId,
      // 表格数据
      tableData: {
        title: [
          {
            title: this.$i18n.t("label.name"),
            prop: "recordname",
            sortable: "custom",
          },
          {
            title: this.$i18n.t("label.type"),
            prop: "objlabel",
            sortable: "custom",
          },
          {
            title: this.$i18n.t("label.delete.persion"),
            prop: "username",
            sortable: "custom",
          },
          {
            title: this.$i18n.t("label.delete.date"),
            prop: "deletiondate",
            sortable: "custom",
          },
        ],
        data: [],
      },
      // 加载
      loading: false,
      // 当前页
      indexPage: 0,
      // 刷新时间
      refreshDate: "",
      // 查询条件
      form: {
        page: 1,
        pageSize: 20,
        sort: "recordname",
        dir: "desc",
        reseachName: "",
        delScope: "1",
      },
      // 被选数据
      selectVal: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    /**
     * 查询
     */
    getTableData() {
      this.loading = true;
      api
        .getInstance()
        .list(this.$devHttp, {
          ...this.form,
        })
        .then((res) => {
          if (res.result) {
            this.tableData.data = this.tableData.data.concat(res.data.dataList);
            this.loading = false;
          }
        });
    },
    /**
     * 滚动加载
     */
    loadScroll() {
      if (this.indexPage > 0) {
        let currentDate = dayjs();
        if (currentDate - this.refreshDate > 2000) {
          this.indexPage++;
          this.form.page = this.indexPage;
          this.getTableData();
          this.refreshDate = currentDate;
        }
      } else {
        this.indexPage++;
      }
    },
    /**
     * 选择项
     */
    handleSelectionChange(val) {
      this.selectVal = val;
    },
    /**
     * 排序
     */
    handleSortChange(val) {
      this.form.sort = val.prop;
      this.form.dir = val.order == "ascending" ? "asc" : "desc";
      this.indexPage = 0;
      this.form.page = 1;
      this.tableData.data = [];
      this.getTableData();
    },
    /**
     * 搜索
     */
    handleSeacher() {
      // 初始化数据
      this.tableData.data = [];
      this.indexPage = 0;
      this.form.page = 1;
      this.getTableData();
    },
    /**
     * 取消删除
     */
    handleCancelDelete() {
      if (this.selectVal.length > 0) {
        let ids = [];
        this.selectVal.forEach((item) => {
          ids.push(item.id);
        });
        ids = ids.toString();
        api
          .getInstance()
          .unDelete(this.$devHttp, {
            ids: ids,
          })
          .then((res) => {
            if (res.result && res.data == null) {
              this.handleSeacher();
            }
          });
      } else {
        this.$message.warning(this.$i18n.t("c308"));
      }
    },
    /**
     * 清空回收站
     */
    handleClearRecycle(val) {
      this.$confirm(
        this.$i18n.t("label.recycle.clearconfirm"),
        this.$i18n.t("label.prompt"),
        {
          confirmButtonText: this.$i18n.t("label.confirm"),
          cancelButtonText: this.$i18n.t("label.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          api
            .getInstance()
            .clear(this.$devHttp, {
              delScope: val,
            })
            .then((res) => {
              if (res.result && res.data == null) {
                this.handleSeacher();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$i18n.t("label.projectManagement.cancelled"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.recycle {
  width: 98.5%;
  height: 97%;
  background-color: #ffffff;
  margin: 10px auto;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  .head {
    .new_title {
      font-size: 18px;
    }
    .desc {
      padding: 12px 0px;
    }
  }
  .content {
    height: calc(100% - 100px);
    border: 1px solid #d8dde6;
    padding: 8px;
    .seach {
      .el-select,
      .el-input {
        width: 200px;
        margin-right: 8px;
      }
    }
    .cusTable {
      height: calc(100% - 30px);
      .myTable {
        border-radius: 4px;
      }
    }
  }
}
</style>