<template>
  <el-table-column
    :label="coloumnHeader.label"
    :prop="coloumnHeader.prop"
    align="center"
  >
    <template v-for="item in coloumnHeader.childList">
      <!-- 满足条件后递归 -->
      <tableColumn
        v-if="item.childList && item.childList.length"
        :key="item.id"
        :coloumn-header="item"
      ></tableColumn>
      <el-table-column
        v-else
        :width="item.width"
        :key="item.name"
        :sortable="item.sortable"
        :sort-method="sortData(item.prop)"
        :label="item.label"
        :prop="item.prop"
        align="center"
      >
        <template slot-scope="scope">
          <span v-html="scope.row[item.prop]" class="myHtml"> </span>
        </template>
      </el-table-column>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "tableColumn",
  props: {
    coloumnHeader: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * 修改排序
     */
    sortData(prop) {
      return function sort(a, b) {
        if ("-" == a[prop]) {
          return -1;
        }
        return parseFloat(a[prop]) - parseFloat(b[prop]);
      };
    },
  },
};
</script>
<style  scoped>
.myHtml {
  white-space: pre-wrap;
}
</style>